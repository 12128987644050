import React, { useEffect, useState } from "react";
import FarmerComponent from "../../components/Farmer/Farmer.js";

const Farmer = () => {
  //   return
  return (
    <div style={{ marginTop: "3.5rem" }}>
      <FarmerComponent />
    </div>
  );
};

export default Farmer;
