import colors from '../../config/colors';

export const styles = {
    displayCardHeader: {
        backgroundColor: colors.green,
        color: colors.white,
    },
    errorStyle: { 
        color: "red" ,
        fontSize: ".9rem",
        marginTop: 0,
    }
};